import { lorem } from "../../utils/lorem"
import { TickIcon } from "../icons/Man"
import * as React from "react"
import { DollarIcon } from "../icons/Dollar"
import { ManIcon } from "../icons/Tick"
import { routes } from "../../page-data/routes"
// @ts-ignore
import news1 from "../../images/gt-map.png"
// @ts-ignore
import news2 from "../../images/tick-questing.png"
// @ts-ignore
import news3 from "../../images/award.png"
import { GlobeIcon } from "../icons/Globe"
import { ResearchIcon } from "../icons/Research"
import { GraphIcon } from "../icons/GraphIcon"
import { Link } from "rebass/styled-components"
import { StorymapImageWithShapes } from "./StorymapImageWithShapes"
import { DataExplorerImageWithShapes } from "./DataExplorerImageWithShapes"
import { theme } from "../../theme"

export const description = (
  <span>
    <span style={{ opacity: 0.7 }}>An initiative led by the </span>
    <Link
      target="_blank"
      href="https://publichealth.jhu.edu/spatial-science-for-public-health-center"
      style={{
        color: "currentColor",
        textDecoration: "underline",
      }}
    >
      Johns Hopkins Spatial Science for Public Health Center
    </Link>
    <span style={{ opacity: 0.7 }}>, in collaboration with the </span>
    <Link
      target="_blank"
      href="https://www.hopkinslyme.org/"
      style={{
        color: "currentColor",
        textDecoration: "underline",
      }}
    >
      Johns Hopkins Medicine Lyme Disease Research Center
    </Link>
    <span style={{ opacity: 0.7 }}>&nbsp;and&nbsp;the&nbsp;</span>
    <Link
      target="_blank"
      href="https://publichealth.jhu.edu/giving/get-inspired/johns-hopkins-lyme-and-tickborne-diseases-research-and-education-institute"
      style={{
        color: "currentColor",
        textDecoration: "underline",
      }}
    >
      Johns Hopkins Lyme and Tickborne Diseases Research and Education Institute
    </Link>
  </span>
)

export const data = {
  hero: {
    heading:
      "Welcome to the Johns Hopkins Lyme and Tickborne Diseases Dashboard",
    headingStyled: "Tickborne Diseases",
    subheading:
      "Harnessing the Power of Geography in Tickborne Diseases Research",
    ctaButtonText: "Read Our Story “Geography, Ticks and You”",
    description,
  },

  whyShouldICare: {
    subheading: "lyme and tickborne disease",
    heading: "Why We Should Care",
    items: [
      {
        icon: <ManIcon />,
        title: "476,000",
        content: `CDC estimates that approximately 476,000 Americans are diagnosed and treated for Lyme disease each year, but less than a tenth of these cases are officially reported.`,
        url: "https://www.cdc.gov/lyme/stats/humancases.html",
        source: (
          <Link
            target="_blank"
            href={"https://www.cdc.gov/lyme/stats/humancases.html"}
            color={theme.colors.link}
          >
            US Centers For Disease Control and Prevention (CDC)
          </Link>
        ),
      },
      {
        icon: <GlobeIcon />,
        title: "50+",
        content: `Lyme disease cases are increasing, and positive tests have been observed in all 50 US states. But Lyme disease is not just a US problem. Lyme and other tickborne diseases are found worldwide, including Canada and parts of Europe, Asia, and Africa. `,
        url:
          "https://academic.oup.com/ajcp/article/152/1/91/5461358?login=true",
        source: (
          <span>
            <Link
              target="_blank"
              href="https://academic.oup.com/ajcp/article/152/1/91/5461358?login=true"
              color={theme.colors.link}
            >
              Quest Diagnostics
            </Link>
            ,{" "}
            <Link
              target="_blank"
              href={"https://www.cdc.gov/ticks/tickbornediseases/abroad.html"}
              color={theme.colors.link}
            >
              CDC
            </Link>
          </span>
        ),
      },
      {
        icon: <DollarIcon />,
        title: "$1.3 billion",
        content:
          "Patients diagnosed with Lyme disease on average have nearly $3,000 higher annual health care costs, which" +
          " adds up to a $712 million – $1.3 billion  burden on the US health care system each year.",
        url:
          "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0116767",
        source: (
          <Link
            target="_blank"
            href={
              "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0116767"
            }
            color={theme.colors.link}
          >
            John Hopkins Bloomberg School of Public Health
          </Link>
        ),
      },
      {
        icon: <ResearchIcon />,
        title: "$1,070",
        content:
          "Lyme disease is a major, global vector-borne disease, but research for improved prevention, diagnosis and treatment lags other diseases. Federal funding per new reported case of Lyme disease ($1,070) is a fraction of that for HIV/AIDS ($97,648) and hepatitis C ($47,522).",
        url:
          "https://www.hhs.gov/sites/default/files/tbdwg-report-to-congress-2018.pdf",
        source: (
          <Link
            target="_blank"
            href={
              "https://www.hhs.gov/sites/default/files/tbdwg-report-to-congress-2018.pdf"
            }
            color={theme.colors.link}
          >
            Tickborne Disease Working Group - US Department of Health and Human
            Services
          </Link>
        ),
      },
    ],
  },

  search: {
    heading: "What’s going on where I live?",
    subheading:
      "Search the reported incidence of Lyme disease in your US county or Canadian health region:",
    buttonText: "Check",
    inputPlaceholder: "Search for your county or health region",
    chartTitle: "Plot of Lyme Disease Incidence",
    kpiName: "2018 Lyme disease incidence*",
    kpiValue: "1.337",
    kpiInsights: [
      `Lyme disease incidence rates are calculated as the number of reported confirmed and probable cases per 100,000 people.`,
      `Reported cases are key to showing trends and geographic patterns, but they do not capture the magnitude of underreporting, which in the US is expected to be more than tenfold.`,
      `Although estimating the true incidence of Lyme disease is challenging, the Johns Hopkins Lyme and Tickborne Diseases Dashboard is currently pursuing alternative methods of estimating and mapping cases, such as through health records, insurance claims, and laboratory testing data.`,
    ],
    kpiDescription:
      "*Incidence is calculated as the number of reported confirmed and probable cases per 100,000 people. Although reported cases are key to showing trends and geographic patterns, they do not capture the magnitude of underreporting, which is expected to be more than tenfold.",
  },

  storymapSection: {
    heading: "Geography, Ticks and You",
    subheading:
      "Why does geography matter for tickborne diseases and why it’s so complicated",
    buttonText: "Read Article “Geography, Ticks and You”",
  },

  linksSection: {
    items: [
      {
        title: (
          <span>
            Geography, <br />
            Ticks and You
          </span>
        ),
        description: `Discover why tickborne diseases are a growing public health threat and why aspects of geography, including climate change, land use, and ecology matter for tickborne disease risk.`,
        icon: <TickIcon size={56} />,
        image: <StorymapImageWithShapes />,
        url: routes.storymap,
        buttonText: (
          <span>Read Our Story “Geography,&nbsp;Ticks&nbsp;and&nbsp;You”</span>
        ),
      },
      {
        title: (
          <span>
            Data <br />
            Explorer
          </span>
        ),
        description: `Use our interactive Data Explorer to visualize tickborne disease-related maps and download the data for further exploration. Our innovative tool is designed to inspire research and scientific collaboration.`,
        icon: <GraphIcon />,
        image: <DataExplorerImageWithShapes />,
        url: routes.explorer,
        buttonText: "Go to Data Explorer",
      },
    ],
  },

  ctaSection: {
    subheading: "want to know more?",
    heading: "Know your enemy",
    text:
      "For those who want to know more, we have prepared a special dashboard on which we have meticulously collected more detailed information about Lyme disease.",
    buttonText: "Go to Dashboard",
  },

  news: {
    subheading: "What’s New",
    items: [
      {
        title:
          "Identifying the Geographic Leading Edge of Lyme Disease Using Google Health Trends",
        description: "New research published in PLOS ONE.",
        img: news1,
        url: "https://doi.org/10.1371/journal.pone.0312277",
        buttonText: "Learn More",
      },
      {
        title: "Johns Hopkins Magazine: ‘A tracker for tickborne illness’",
        description:
          "Johns Hopkins Magazine features the dashboard in its Spring 2022 issue.",
        img: news2,
        url: "https://hub.jhu.edu/magazine/2022/spring/lyme-tracker-map/",
        buttonText: "Learn More",
      },
      {
        title: "Dashboard wins Health Data Visualization Award",
        description:
          "The Health and Medical Geography Specialty Group of the American Association of Geographers awarded the dashboard an interactive visualization award at the 2022 AAG Annual Meeting.",
        img: news3,
        url: "",
        buttonText: "",
      },
    ],
  },
}
